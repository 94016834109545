import React from 'react';
import styled from "styled-components";
import Layout from 'src/components/Layout';
import { Row, Col, ColFlex } from 'src/components/Grid';
import { mediaDesktopFirst } from 'src/helpers/responsive';
import { vBlockSize } from 'src/components/GlobalStyle';
import { graphql } from 'gatsby';

const HeroContainer = styled.div`
  padding: ${vBlockSize}px 0 ${vBlockSize}px;
  margin-bottom: ${vBlockSize}px;
  /* background: #cbd0d3; */
  background: #dce1e5;
  ${ColFlex} {
    display: flex;
    flex-direction: row;
    align-items: center;
    h1 {
      line-height: 1em;
    }
    .h2 {
      margin-bottom: ${vBlockSize}px;
      ${mediaDesktopFirst(`
      `, `
        margin-bottom: ${0.5 * vBlockSize}px;
      `, `
        font-size: 24px;
      `)}
    }
  }
`;

const Content = styled.div`
  margin-bottom: ${2 * vBlockSize}px;
  & > *:first-child {
    margin-top: ${vBlockSize}px;
  }
  h1, h2, h3 {
    margin-top: ${2 * vBlockSize}px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    margin-top: ${vBlockSize}px;
  }
  p img {
    display: inline-block;
    padding: ${0.5 * vBlockSize}px 0;
  }
  ul {
    list-style: circle;
    padding-left: 18px;
    margin-bottom: ${0.5 * vBlockSize}px;
  }
`;

export const Page = ({ preview = false, metaTitle = '', title = '', intro = '', body, children = null }) => (
  <>
    <HeroContainer>
      <Row>
        <ColFlex>
          <div>
            {title && (
              <h1>{title}</h1>
            )}
            {intro && (
              <p className="h2">{intro}</p>
            )}
          </div>
        </ColFlex>
        <Col s0={0} s1={4} s2={3}>
        </Col>
      </Row>
    </HeroContainer>
    <Row>
      <ColFlex>
        {children ? (
          <Content>{children}</Content>
        ) : preview ? (
          <Content>{body}</Content>
        ) : (
          <Content dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </ColFlex>
    </Row>
  </>
);

const Index = ({ data: { markdownRemark: { html, frontmatter } } }) => (
  <Page body={html} {...frontmatter} />
);

export default Index;


export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        slug
        metaTitle
        title
        intro
      }
    }
  }
`
