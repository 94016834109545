import React from 'react';
import { Page } from 'src/templates/page';
import { RowCol } from 'src/components/Grid';

const NotFound = () => (
  <Page 
    metaTitle="404 - Page not found"
    title="404"
    intro="Page not found :("
  >
    <RowCol>
      <p>Looks like this page is on holiday.</p>
    </RowCol>
  </Page>
);

export default NotFound;
